<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card :title="$t('pamm_account_title',{name: account.nick_name})">
      <b-row>
        <b-col cols="12">
          <h4>
            {{ $t('pamm_account_description1') }}
          </h4>
          <h6>
            {{ $t('pamm_account_description2') }}
          </h6>
        </b-col>
        <b-col
          cols="12"
          class="mt-2"
        >
          <b-table
            :items="investingElements"
            :fields="investingFields"
            striped
            sticky-header="1000px"
          >
            <template #cell(title)="data">
              <span class="text-primary font-weight-bold text-nowrap">{{ data.item["title"] }}</span>
            </template>
            <template #cell(description)="data">
              <span class="font-weight-light">{{ data.item["description"] }}</span>
            </template>
            <template #cell(value)="data">
              <span class="text-primary font-weight-bold text-nowrap">{{ data.item["value"] }}</span>
            </template>
            <template #cell(action)="data">
              <b-button
                disabled
                variant="warning"
                class="mx-1"
              >
                {{ $t('details_show') }}
              </b-button>
              <b-button
                :to="{ name: 'pamm_account', params: { id: data.item.id } }"
                variant="success"
                class="mx-1"
              >
                {{ $t('investing') }}
              </b-button>
            </template>
          </b-table>
        </b-col>
        <b-col
          cols="12"
          class="mt-1"
        >
          <b-form-group>
            <b-form-checkbox
              id="isAgree"
              v-model="isAgree"
              name="isAgree"
            >
              {{ $t('pamm_invest_conditions') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-row>
            <b-col
              cols="12"
              sm="4"
              class="mt-1"
            >
              <b-button
                :href="`https://pamm.test-cabin.com/account/account-details/${$route.params.id}`"
                target="_blank"
                block
                variant="warning"
              >
                {{ $t('details_show') }}
              </b-button>
            </b-col>
            <b-col
              cols="12"
              sm="8"
              class="mt-1"
            >
              <b-button
                :disabled="!isAgree"
                block
                variant="success"
                @click="invest"
              >
                {{ $t('start_investing') }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import { alphanumeric } from '@/mixins/alphanumeric'
import PammApis from '@/modules/pamm/service/apis/index'

const pammApis = new PammApis()

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormCheckbox,
  },
  mixins: [alphanumeric],
  data() {
    return {
      show: false,
      required,
      isAgree: false,
      account: {
        id: 103,
        nick_name: 'hamid_wolf1366',
        balance: 1000,
        caneclation_period: 30,
        caneclation_fee: 10,
        fee_static: 10,
        fee_percent: 1.5,
        loss_share: 5,
        profit_share: 10,
        minimum_investment: 300,
        maximum_investment: 1000,
        rank: 1,
        created_at: new Date(),
        initial_capital: 100,
      },
    }
  },
  computed: {
    investingFields() {
      return [
        {
          label: this.$t('title'),
          key: 'title',
        },
        {
          label: this.$t('description'),
          key: 'description',
        },
        {
          label: this.$t('value'),
          key: 'value',
        },
      ]
    },
    investingElements() {
      return [
        {
          title: this.$t('caneclation_period'),
          description: this.$t('caneclation_period_description'),
          value: null,
        },
        {
          title: this.$t('caneclation_fee'),
          description: this.$t('caneclation_fee_description'),
          value: null,
        },
        {
          title: this.$t('fee_static'),
          description: this.$t('fee_static_description'),
          value: null,
        },
        {
          title: this.$t('fee_percent'),
          description: this.$t('fee_percent_description'),
          value: null,
        },
        {
          title: this.$t('loss_share'),
          description: this.$t('loss_share_description'),
          value: null,
        },
        {
          title: this.$t('profit_share'),
          description: this.$t('profit_share_description'),
          value: null,
        },
        {
          title: this.$t('minimum_investment'),
          description: this.$t('minimum_investment_description'),
          value: null,
        },
        {
          title: this.$t('maximum_investment'),
          description: this.$t('maximum_investment_description'),
          value: null,
        },
      ]
    },
  },
  mounted() {
    this.getAccountInfo()
  },
  methods: {
    getAccountInfo() {
      this.show = false

      this.investingElements[0].value = `${30} ${this.$t('day')}`
      this.investingElements[1].value = `${10} $`
      this.investingElements[2].value = `${10} $`
      this.investingElements[3].value = `${1.25} %`
      this.investingElements[4].value = `${5} %`
      this.investingElements[5].value = `${15} %`
      this.investingElements[6].value = `${100} $`
      this.investingElements[7].value = `${2000} $`

      console.log(this.$route.params.id)
    },
    invest() {
      console.log('call invest api')
    },
  },
}
</script>
