import {
  LayersIcon, Minimize2Icon, Maximize2Icon, ListIcon,
} from 'vue-feather-icons'

export default [{
  title: 'pamm',
  icon: LayersIcon,
  children: [
    {
      title: 'pamm_own_account',
      icon: Minimize2Icon,
      route: 'pamm_management_account',
    },
    {
      title: 'pamm_own_invest',
      route: 'pamm_own_invest',
      icon: Maximize2Icon,
    },
    {
      title: 'pamm_invest',
      route: 'pamm_invest',
      icon: ListIcon,
    },
  ],
}]
