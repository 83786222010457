<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card :title="$t('trades_list')">
      <b-row>
        <b-col
          cols="12"
          class="mb-1"
        >
          {{ $t('trades_list_description',{ name: account.nick_name }) }}
        </b-col>
        <b-col cols="12">
          <b-table
            :items="transactions"
            :fields="transactionFields"
            striped
            sticky-header="1000px"
          >
            <template #cell(close_time)="data">
              <span>{{ getFormattedDate(data.item["close_time"]) }}</span>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BTable,
} from 'bootstrap-vue'
import moment from '@/libs/moment'

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BTable,
  },
  data() {
    return {
      show: false,
      account: {
        nick_name: 'hamid_wolf1366',
        caneclation_period: 30,
        caneclation_fee: 10,
        fee_static: 10,
        fee_percent: 1.5,
        loss_share: 5,
        profit_share: 10,
        minimum_investment: 300,
        maximum_investment: 1000,
        initial_capital: 100,
      },
      transactions: [
        {
          id: 1,
          close_time: new Date(),
          open_price: 2,
          close_price: 1,
          volume: 1,
          profit: 100,
          symbol: 'USDEUR',
        },
        {
          id: 2,
          close_time: new Date(),
          open_price: 2,
          close_price: 1,
          volume: 1,
          profit: 100,
          symbol: 'USDEUR',
        },
        {
          id: 3,
          close_time: new Date(),
          open_price: 2,
          close_price: 1,
          volume: 1,
          profit: 100,
          symbol: 'USDEUR',
        },
      ],
    }
  },
  computed: {
    transactionFields() {
      return [
        {
          key: 'id',
          value: this.$t('id'),
        },
        {
          key: 'symbol',
          value: this.$t('symbol'),
        },
        {
          key: 'open_price',
          value: this.$t('open_price'),
        },
        {
          key: 'close_price',
          value: this.$t('close_price'),
        },
        {
          key: 'volume',
          value: this.$t('volume'),
        },
        {
          key: 'profit',
          value: this.$t('profit'),
        },
        {
          key: 'close_time',
          value: this.$t('close_time'),
        },
      ]
    },
  },
  methods: {
    getFormattedDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
  },
}
</script>
