<template>
  <b-card :title="$t('list_pamm_account')">
    <b-row>
      <b-col
        cols="12"
        class=""
      >
        <b-alert
          show
          variant="success"
          class="p-2"
        >
          <h5>
            {{ $t('list_pamm_account_title') }}
          </h5>
        </b-alert>
      </b-col>
      <b-col
        cols="12"
        class="mb-3 text-secondary"
      >
        {{ $t('list_pamm_account_description') }}
      </b-col>
      <b-col
        v-if="accounts.length > 0"
        align-self="center"
        cols="12"
        sm="4"
        class="mb-1"
      >
        <b-input-group
          size="md"
        >
          <b-form-input
            v-model="search"
            name="account_search"
            :placeholder="$t('search')"
            type="text"
          />
          <b-input-group-append>
            <b-button
              :disabled="!search"
              @click="searchAccount()"
            >
              <feather-icon
                size="14"
                icon="SearchIcon"
              />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col
        v-if="accounts.length > 0"
        cols="12"
      >
        <b-overlay
          :show="accountsShow"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <b-table
            :items="accounts"
            :fields="accountFields"
            striped
            sticky-header="1000px"
          >
            <template #cell(created_at)="data">
              <span>{{ getFormattedDate(data.item["created_at"]) }}</span>
            </template>
            <template #cell(action)="data">
              <b-button
                :href="`https://pamm.test-cabin.com/account/account-details/${data.item.id}`"
                target="_blank"
                variant="warning"
                class="mx-1"
              >
                {{ $t('details_show') }}
              </b-button>
              <b-button
                :to="{ name: 'pamm_account', params: { id: data.item.id } }"
                variant="success"
                class="mx-1"
              >
                {{ $t('investing') }}
              </b-button>
            </template>
          </b-table>
        </b-overlay>
        <b-row
          v-if="pagination.total > pagination.per_page"
          align-h="center"
          class="mt-2"
        >
          <b-col cols="auto">
            <b-pagination
              v-model="pagination.current_page"
              :total-rows="pagination.total"
              :per-page="pagination.per_page"
              size="sm"
              @input="changePage()"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col v-if="accounts.length === 0 && !accountsShow">
        <h5 class="text-center py-3 text-danger">
          {{ $t('no_account_to_show') }}
        </h5>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BPagination,
  BTable,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from 'bootstrap-vue'
// import PammApis from '@/modules/pamm/service/apis/index'
import moment from '@/libs/moment'

// const pammApis = new PammApis()

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BPagination,
    BTable,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  data() {
    return {
      show: false,
      accountsShow: false,
      accounts: [
        {
          id: 103,
          nick_name: 'hamid_wolf1366',
          balance: 1000,
          caneclation_period: 30,
          caneclation_fee: 10,
          fee_static: 10,
          fee_percent: 1.5,
          loss_share: 5,
          profit_share: 10,
          minimum_investment: 300,
          maximum_investment: 1000,
          rank: 1,
          created_at: new Date(),
          initial_capital: 100,
        },
        {
          id: 104,
          nick_name: 'ٍJenifer1987',
          balance: 1000,
          caneclation_period: 30,
          caneclation_fee: 10,
          fee_static: 10,
          fee_percent: 1.5,
          loss_share: 5,
          profit_share: 10,
          minimum_investment: 300,
          maximum_investment: 1000,
          rank: 1,
          created_at: new Date(),
          initial_capital: 100,
        },
        {
          id: 1111,
          nick_name: 'SalmanKhan111',
          balance: 1000,
          caneclation_period: 30,
          caneclation_fee: 10,
          fee_static: 10,
          fee_percent: 1.5,
          loss_share: 5,
          profit_share: 10,
          minimum_investment: 300,
          maximum_investment: 1000,
          rank: 1,
          created_at: new Date(),
          initial_capital: 100,
        },
      ],
      search: '',
      pagination: {
        current_page: 1,
        total: 0,
        per_page: 3,
      },
    }
  },
  computed: {
    accountFields() {
      return [
        {
          label: this.$t('id'),
          key: 'id',
        },
        {
          label: this.$t('nick_name'),
          key: 'nick_name',
        },
        {
          label: this.$t('balance'),
          key: 'balance',
        },
        {
          label: this.$t('created_at'),
          key: 'created_at',
        },
        {
          label: this.$t('initial_capital'),
          key: 'initial_capital',
        },
        {
          label: this.$t('rank'),
          key: 'rank',
        },
        {
          label: this.$t('action'),
          key: 'action',
        },
      ]
    },
  },
  mounted() {
    this.getAccounts()
  },
  methods: {
    getAccounts() {
      this.accountsShow = false
    },
    searchAccount() {
      this.pagination.current_page = 1
      this.getAccounts()
    },
    changePage() {
      this.getAccounts()
    },
    getFormattedDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
  },
}
</script>
