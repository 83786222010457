<template>
  <b-card
    style="box-shadow: 0 0 10px 10px rgba(0, 0, 0, .01) !important"
    class="mx-2"
  >
    <p
      class="font-weight-bold mt-2"
      style="font-size: 1.7rem;"
    >
      {{ account.rank }}
    </p>
    <h2
      style="font-size: 2rem;"
      class="mb-3"
    >
      {{ account.nick_name }}
    </h2>
    <b-row align-h="around">
      <b-col cols="auto">
        <p>{{ $t('id') }}</p>
        <p class="font-weight-bold">
          {{ account.id }}
        </p>
      </b-col>
      <b-col cols="auto">
        <p>{{ $t('balance') }}</p>
        <p class="font-weight-bold">
          {{ account.balance }}
        </p>
      </b-col>
      <b-col cols="auto">
        <p>{{ $t('status') }}</p>
        <p
          v-if="account.activate"
          class="font-weight-bold text-success"
        >
          {{ $t('active') }}
        </p>
        <p
          v-else
          class="font-weight-bold text-danger"
        >
          {{ $t('not_active') }}
        </p>
      </b-col>
    </b-row>
    <hr>
    <b-row class="mt-2">
      <b-col
        cols="12"
        sm="6"
      >
        <b-button
          class="my-1 pamm-button"
          block
          :to="{ name: 'pamm_management_dashboard', params: { id: account.id}}"
        >
          {{ $t('dashboard') }}
        </b-button>
      </b-col>
      <b-col
        cols="12"
        sm="6"
      >
        <b-button
          class="my-1 pamm-button"
          block
          :to="{ name: 'pamm_management_edit', params: { id: account.id } }"
        >
          {{ $t('edit_account') }}
        </b-button>
      </b-col>
      <b-col
        cols="12"
        sm="6"
      >
        <b-button
          class="my-1 pamm-button"
          block
          :href="`https://pamm.test-cabin.com/account/account-details/${account.id}`"
          target="_blank"
        >
          {{ $t('account_page') }}
        </b-button>
      </b-col>
      <b-col
        cols="12"
        sm="6"
      >
        <b-button
          class="my-1 pamm-button"
          :to="{ name: 'pamm_management_trades', params: { id: account.id } }"
          block
        >
          {{ $t('trades') }}
        </b-button>
      </b-col>
      <b-col
        cols="12"
        sm="6"
      >
        <b-button
          class="my-1 pamm-button"
          block
        >
          {{ $t('commisions') }}
        </b-button>
      </b-col>
      <b-col
        cols="12"
        sm="6"
      >
        <b-button
          class="my-1 pamm-button"
          block
        >
          {{ $t('investors') }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
  },
  props: ['account'],
}
</script>
