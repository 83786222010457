export default [
  {
    path: '/:lang/pamm/invest/account',
    name: 'pamm_invest',
    component: () => import('@/modules/pamm/views/investing/list.vue'),
    meta: {
      navActiveLink: 'pamm_invest',
      pageTitle: 'pamm_invest',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'list',
          active: true,
          to: {
            name: 'pamm_invest',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/pamm/invest/account/:id',
    name: 'pamm_account',
    component: () => import('@/modules/pamm/views/investing/account.vue'),
    meta: {
      navActiveLink: 'pamm_invest',
      pageTitle: 'pamm_invest',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'list',
          active: false,
          to: {
            name: 'pamm_invest',
          },
        },
        {
          text: 'account',
          active: true,
          to: {
            name: 'pamm_account',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/pamm/management/invest',
    name: 'pamm_own_invest',
    component: () => import('@/modules/pamm/views/my-invest/my-invest.vue'),
    meta: {
      navActiveLink: 'pamm_own_invest',
      pageTitle: 'pamm_own_invest',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'pamm_own_invest',
          active: true,
          to: {
            name: 'pamm_own_invest',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/pamm/management/invest/:id/dashboard',
    name: 'pamm_invest_dashboard',
    component: () => import('@/modules/pamm/views/my-account/dashboard.vue'),
    meta: {
      navActiveLink: 'pamm_own_invest',
      pageTitle: 'account_dashboard',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'pamm_own_invest',
          active: true,
          to: {
            name: 'pamm_invest_dashboard',
          },
        },
        {
          text: 'account_dashboard',
          active: true,
          to: {
            name: 'pamm_invest_dashboard',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/pamm/management/invest/:id/trades',
    name: 'pamm_invest_trades',
    component: () => import('@/modules/pamm/views/my-account/trades.vue'),
    meta: {
      navActiveLink: 'pamm_own_invest',
      pageTitle: 'account_management',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'pamm_own_invest',
          active: true,
          to: {
            name: 'pamm_own_invest',
          },
        },
        {
          text: 'account_management',
          active: true,
          to: {
            name: 'pamm_invest_dashboard',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/pamm/management/account',
    name: 'pamm_management_account',
    component: () => import('@/modules/pamm/views/my-account/management.vue'),
    meta: {
      navActiveLink: 'pamm_management_account',
      pageTitle: 'account_management',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'pamm_own_account',
          active: false,
          to: {
            name: '',
          },
        },
        {
          text: 'account_management',
          active: true,
          to: {
            name: 'pamm_management_account',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/pamm/management/create',
    name: 'pamm_management_create',
    component: () => import('@/modules/pamm/views/my-account/create.vue'),
    meta: {
      navActiveLink: 'pamm_management_account',
      pageTitle: 'pamm_account_create',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'pamm_own_account',
          active: false,
          to: {
            name: 'pamm_management_account',
          },
        },
        {
          text: 'pamm_account_create',
          active: true,
          to: {
            name: 'pamm_management_create',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/pamm/management/account/:id/edit',
    name: 'pamm_management_edit',
    component: () => import('@/modules/pamm/views/my-account/edit-account.vue'),
    meta: {
      navActiveLink: 'pamm_management_account',
      pageTitle: 'pamm_account_create',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'pamm_own_account',
          active: false,
          to: {
            name: 'pamm_management_account',
          },
        },
        {
          text: 'pamm_account_edit',
          active: true,
          to: {
            name: 'pamm_management_edit',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/pamm/management/account/:id/trades',
    name: 'pamm_management_trades',
    component: () => import('@/modules/pamm/views/my-account/trades.vue'),
    meta: {
      navActiveLink: 'pamm_management_account',
      pageTitle: 'account_management',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'pamm_own_account',
          active: false,
          to: {
            name: '',
          },
        },
        {
          text: 'account_trades',
          active: true,
          to: {
            name: 'pamm_management_trades',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/pamm/management/account/:id/dashboard',
    name: 'pamm_management_dashboard',
    component: () => import('@/modules/pamm/views/my-account/dashboard.vue'),
    meta: {
      navActiveLink: 'pamm_management_account',
      pageTitle: 'account_management',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'pamm_own_account',
          active: false,
          to: {
            name: '',
          },
        },
        {
          text: 'account_dashboard',
          active: true,
          to: {
            name: 'pamm_management_dashboard',
          },
        },
      ],
    },
  },
]
