<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card :title="$t('account_dashboard')">
      <b-row>
        <b-col
          cols="12"
          class="mb-1"
        >
          {{ $t('account_dashboard_description') }}
        </b-col>
        <b-col cols="12">
          dashboard
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'
import moment from '@/libs/moment'

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
  },
  data() {
    return {
      show: false,
      account: {
        nick_name: 'hamid_wolf1366',
        caneclation_period: 30,
        caneclation_fee: 10,
        fee_static: 10,
        fee_percent: 1.5,
        loss_share: 5,
        profit_share: 10,
        minimum_investment: 300,
        maximum_investment: 1000,
        initial_capital: 100,
      },
    }
  },
  methods: {
    getFormattedDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
  },
}
</script>
