<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card :title="$t('pamm_new_account_create')">
      <b-row>
        <b-col cols="12">
          <p>{{ $t('pamm_account_create_description') }}</p>
          <p>{{ $t('pamm_account_create_description1') }}</p>
          <p>{{ $t('pamm_account_create_description2') }}</p>
        </b-col>
        <b-col
          cols="12"
          class="mt-2"
        >
          <validation-observer v-slot="{ invalid }">
            <b-form>
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    :label="$t('account_nickname')"
                    label-for="account_nickname"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('account_nickname')"
                      rules="required"
                    >
                      <b-form-input
                        v-model="account.nick_name"
                        name="account_nickname"
                        :placeholder="$t('account_nickname')"
                        autocomplete="nope"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('manager_initial_capital')"
                      rules="required"
                    >
                      <label for="manager_initial_capital">
                        <span>{{ $t('manager_initial_capital') }}</span>
                      </label>
                      <b-form-input
                        v-model="account.initial_capital"
                        name="manager_initial_capital"
                        :placeholder="$t('manager_initial_capital')"
                        autocomplete="nope"
                        @keypress="AlphaNumeric($event)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('caneclation_period')"
                      rules="required"
                    >
                      <label for="caneclation_period">
                        <span>{{ $t('caneclation_period') }}</span>
                        <feather-icon
                          id="popover-caneclation_period"
                          icon="HelpCircleIcon"
                          size="18"
                          style="margin: 0 4px 4px;"
                          href="#"
                          tabindex="0"
                        />
                        <b-popover
                          target="popover-caneclation_period"
                          variant="danger"
                          placement="top"
                          triggers="focus"
                        >
                          <span>{{ $t('caneclation_period_description') }}</span>
                        </b-popover>
                      </label>
                      <b-form-input
                        v-model="account.caneclation_period"
                        name="caneclation_period"
                        :placeholder="$t('caneclation_period')"
                        autocomplete="nope"
                        @keypress="AlphaNumeric($event)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('caneclation_fee')"
                      rules="required"
                    >
                      <label for="caneclation_fee">
                        <span>{{ $t('caneclation_fee') }}</span>
                        <feather-icon
                          id="popover-caneclation_fee"
                          icon="HelpCircleIcon"
                          size="18"
                          style="margin: 0 4px 4px;"
                          href="#"
                          tabindex="0"
                        />
                        <b-popover
                          target="popover-caneclation_fee"
                          variant="danger"
                          placement="top"
                          triggers="focus"
                        >
                          <span>{{ $t('caneclation_fee_description') }}</span>
                        </b-popover>
                      </label>
                      <b-form-input
                        v-model="account.caneclation_fee"
                        name="caneclation_fee"
                        :placeholder="$t('caneclation_fee')"
                        autocomplete="nope"
                        @keypress="AlphaNumeric($event)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('fee_static')"
                      rules="required"
                    >
                      <label for="fee_static">
                        <span>{{ $t('fee_static') }}</span>
                        <feather-icon
                          id="popover-fee_static"
                          icon="HelpCircleIcon"
                          size="18"
                          style="margin: 0 4px 4px;"
                          href="#"
                          tabindex="0"
                        />
                        <b-popover
                          target="popover-fee_static"
                          variant="danger"
                          placement="top"
                          triggers="focus"
                        >
                          <span>{{ $t('fee_static_description') }}</span>
                        </b-popover>
                      </label>
                      <b-form-input
                        v-model="account.fee_static"
                        name="fee_static"
                        :placeholder="$t('fee_static')"
                        autocomplete="nope"
                        @keypress="AlphaNumeric($event)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('fee_percent')"
                      rules="required"
                    >
                      <label for="fee_percent">
                        <span>{{ $t('fee_percent') }}</span>
                        <feather-icon
                          id="popover-fee_percent"
                          icon="HelpCircleIcon"
                          size="18"
                          style="margin: 0 4px 4px;"
                          href="#"
                          tabindex="0"
                        />
                        <b-popover
                          target="popover-fee_percent"
                          variant="danger"
                          placement="top"
                          triggers="focus"
                        >
                          <span>{{ $t('fee_percent_description') }}</span>
                        </b-popover>
                      </label>
                      <b-form-input
                        v-model="account.fee_percent"
                        name="fee_percent"
                        :placeholder="$t('fee_percent')"
                        autocomplete="nope"
                        @keypress="AlphaNumeric($event)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('loss_share')"
                      rules="required"
                    >
                      <label for="loss_share">
                        <span>{{ $t('loss_share') }}</span>
                        <feather-icon
                          id="popover-loss_share"
                          icon="HelpCircleIcon"
                          size="18"
                          style="margin: 0 4px 4px;"
                          href="#"
                          tabindex="0"
                        />
                        <b-popover
                          target="popover-loss_share"
                          variant="danger"
                          placement="top"
                          triggers="focus"
                        >
                          <span>{{ $t('loss_share_description') }}</span>
                        </b-popover>
                      </label>
                      <b-form-input
                        v-model="account.loss_share"
                        name="loss_share"
                        :placeholder="$t('loss_share')"
                        autocomplete="nope"
                        @keypress="AlphaNumeric($event)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('profit_share')"
                      rules="required"
                    >
                      <label for="profit_share">
                        <span>{{ $t('profit_share') }}</span>
                        <feather-icon
                          id="popover-profit_share"
                          icon="HelpCircleIcon"
                          size="18"
                          style="margin: 0 4px 4px;"
                          href="#"
                          tabindex="0"
                        />
                        <b-popover
                          target="popover-profit_share"
                          variant="danger"
                          placement="top"
                          triggers="focus"
                        >
                          <span>{{ $t('profit_share_description') }}</span>
                        </b-popover>
                      </label>
                      <b-form-input
                        v-model="account.profit_share"
                        name="profit_share"
                        :placeholder="$t('profit_share')"
                        autocomplete="nope"
                        @keypress="AlphaNumeric($event)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('minimum_investment')"
                      rules="required"
                    >
                      <label for="minimum_investment">
                        <span>{{ $t('minimum_investment') }}</span>
                        <feather-icon
                          id="popover-minimum_investment"
                          icon="HelpCircleIcon"
                          size="18"
                          style="margin: 0 4px 4px;"
                          href="#"
                          tabindex="0"
                        />
                        <b-popover
                          target="popover-minimum_investment"
                          variant="danger"
                          placement="top"
                          triggers="focus"
                        >
                          <span>{{ $t('minimum_investment_description') }}</span>
                        </b-popover>
                      </label>
                      <b-form-input
                        v-model="account.minimum_investment"
                        name="minimum_investment"
                        :placeholder="$t('minimum_investment')"
                        autocomplete="nope"
                        @keypress="AlphaNumeric($event)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('maximum_investment')"
                      rules="required"
                    >
                      <label for="maximum_investment">
                        <span>{{ $t('maximum_investment') }}</span>
                        <feather-icon
                          id="popover-maximum_investment"
                          icon="HelpCircleIcon"
                          size="18"
                          style="margin:0 4px 4px;"
                          href="#"
                          tabindex="0"
                        />
                        <b-popover
                          target="popover-maximum_investment"
                          variant="danger"
                          placement="top"
                          triggers="focus"
                        >
                          <span>{{ $t('maximum_investment_description') }}</span>
                        </b-popover>
                      </label>
                      <b-form-input
                        v-model="account.maximum_investment"
                        name="maximum_investment"
                        :placeholder="$t('maximum_investment')"
                        autocomplete="nope"
                        @keypress="AlphaNumeric($event)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  class="text-danger"
                  style="cursor: pointer;"
                  cols="12"
                  @click="contractModalShow = true"
                >
                  {{ $t('show_contract_here') }}
                </b-col>
                <b-col
                  cols="12"
                  class="my-1"
                >
                  <b-form-group>
                    <b-form-checkbox
                      id="isAgree"
                      v-model="isAgree"
                      name="isAgree"
                    >
                      {{ $t('pamm_create_account_is_agree') }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-button
                    block
                    variant="primary"
                    :disabled="!isAgree || invalid"
                    @click="completeModalShow = true"
                  >
                    <span v-if="!createPending">
                      {{ $t('complete_request') }}
                    </span>
                    <span v-else>
                      <b-spinner
                        small
                        type="grow"
                      />
                    </span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>

    <!-- Modal -->
    <b-modal
      v-model="completeModalShow"
      centered
      size="lg"
      :title="$t('identification')"
      no-close-on-backdrop
    >
      <h4 class="my-1">
        {{ $t('require_identification') }}
      </h4>
      <h6>
        {{ $t('require_identification_description') }}
      </h6>
      <b-alert
        show
        variant="primary"
        class="p-2"
      >
        <p
          v-for="(item, index) in $t('pamm_agreement_context')"
          :key="index"
        >
          {{ item }}
        </p>
      </b-alert>
      <b-img
        class="mx-auto d-flex justify-center"
        fluid
        width="160"
        :src="require('@/assets/images/image/3.webp')"
      />
      <b-form-file
        v-model="file"
        class="my-1"
        accept="image/png, image/jpg, image/jpeg"
        :placeholder="$t('no_file_chosen')"
      />
      <div>
        <h6 class="text-danger">
          {{ $t('pamm_agreement_notes.title') }}
        </h6>
        <div style="margin-bottom: 4px;">
          <span class="font-weight-bold">{{ $t('pamm_agreement_notes.note_one_title') }}</span>
          <span style="margin: 0 2px;">:</span>
          <span>{{ $t('pamm_agreement_notes.note_one_description') }}</span>
        </div>
        <div style="margin-bottom: 4px;">
          <span class="font-weight-bold">{{ $t('pamm_agreement_notes.note_two_title') }}</span>
          <span style="margin: 0 2px;">:</span>
          <span>{{ $t('pamm_agreement_notes.note_two_description') }}</span>
        </div>
        <div style="margin-bottom: 4px;">
          <span class="font-weight-bold">{{ $t('pamm_agreement_notes.note_three_title') }}</span>
          <span style="margin: 0 2px;">:</span>
          <span>{{ $t('pamm_agreement_notes.note_three_description') }}</span>
        </div>
      </div>
      <div class="my-2">
        <p>{{ $t('pamm_create_request_desc') }}</p>
      </div>
      <template #modal-footer>
        <b-button
          :disabled="!file"
          variant="primary"
          block
          @click="createAccount"
        >
          {{ $t('request_create_account') }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="contractModalShow"
      centered
      size="lg"
      :title="$t('contract')"
      hide-footer
    >
      <h1 style="font-size: 20px; font-weight: bold; text-align: center;">
        PAMM Account Opening Agreement
      </h1>
      <p>This agreement (the "Agreement") is made and entered into as of the [Date] by and between [Your Name], with an address of [Your Address] (the "Client"), and [Company Name], a company duly organized and existing under the laws of [Country] with its principal place of business at [Company Address] (the "Company").</p>
      <p style="font-style: italic;">
        <strong>WHEREAS,</strong> the Client desires to open a PAMM account with the Company; and
      </p>
      <p style="font-style: italic;">
        <strong>WHEREAS,</strong> the Company is willing to accept the Client as a PAMM account holder subject to the terms and conditions of this Agreement.
      </p>
      <h2 style="font-size: 18px; font-weight: bold;">
        NOW, THEREFORE, in consideration of the mutual covenants contained herein, the parties agree as follows:
      </h2>
      <h3><span style="font-weight: bold; text-decoration: underline;">1. Account Opening</span></h3>
      <p>1.1 The Client hereby applies to open a PAMM account with the Company.</p>
      <p>1.2 The Client shall provide the Company with all information and documentation required by the Company to open and maintain the PAMM account, including but not limited to:</p>
      <ul style="list-style-type: disc; padding-left: 20px;">
        <li>Full name and contact information</li>
        <li>Proof of identity</li>
        <li>Proof of address</li>
        <li>Source of funds</li>
        <li>Risk tolerance assessment</li>
      </ul>
      <p>1.3 The Company shall review the Client's application and supporting documentation and, at its sole discretion, approve or deny the application.</p>
      <h3><span style="font-weight: bold; text-decoration: underline;">2. PAMM Account</span></h3>
      <p>2.1 A PAMM account is a type of managed account where the Client's funds are pooled with the funds of other PAMM account holders and invested by a Money Manager.</p>
      <p>2.2 The Client understands and agrees that the Money Manager has sole discretion over the investment of the PAMM account funds.</p>
      <p>2.3 The Client acknowledges and agrees that the Money Manager's investment decisions may result in losses as well as gains.</p>
      <p>2.4 The Client shall not interfere with the Money Manager's investment decisions.</p>
      <h3><span style="font-weight: bold; text-decoration: underline;">3. Fees and Charges</span></h3>
      <p>3.1 The Company shall charge the Client a management fee for the PAMM account. The management fee shall be calculated as a percentage of the PAMM account's net asset value (NAV) on a monthly basis.</p>
      <p>3.2 The Company may also charge the Client other fees and charges, such as withdrawal fees and performance fees.</p>
      <p>3.3 The Client shall be responsible for all applicable taxes on the fees and charges.</p>
      <h3><span style="weight: bold; text-decoration: underline;">4. Risk Disclosure</span></h3>
      <p>4.1 The Client acknowledges and agrees that PAMM accounts are speculative investments and involve a high degree of risk.</p>
      <p>4.2 The Client understands and agrees that there is a possibility that the Client may lose all or a portion of the funds invested in the PAMM account.</p>
      <p>4.3 The Client should not invest in a PAMM account unless the Client can afford to lose the entire investment.</p>
      <h3><span style="font-weight: bold; text-decoration: underline;">5. Termination</span></h3>
      <p>5.1 Either party may terminate this Agreement at any time upon [Notice Period] written notice to the other party.</p>
      <p>5.2 Upon termination of this Agreement, the Client's funds shall be redeemed from the PAMM account and distributed to the Client within [Timeframe].</p>
      **Please note:** This is a basic implementation of inline styles. You can further customize the styles (font size, colors, etc.) based on your specific needs.
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BPopover,
  BModal,
  BAlert,
  BImg,
  BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { alphanumeric } from '@/mixins/alphanumeric'

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BPopover,
    BModal,
    BAlert,
    BImg,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [alphanumeric],
  data() {
    return {
      show: false,
      required,
      isAgree: true,
      createPending: false,
      completeModalShow: false,
      file: null,
      account: {
        nick_name: 'hamid_wolf1366',
        caneclation_period: 30,
        caneclation_fee: 10,
        fee_static: 10,
        fee_percent: 1.5,
        loss_share: 5,
        profit_share: 10,
        minimum_investment: 300,
        maximum_investment: 1000,
        initial_capital: 100,
      },
      contractModalShow: false,
    }
  },
  methods: {
    createAccount() {
      this.createPending = false
      console.log('call api for create pamm account')
    },
  },
}
</script>
