<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card
      v-if="accounts.length > 0"
      :title="$t('pamm_own_account')"
    >
      <h5 class="my-2">
        {{ $t('pamm_own_account_description') }}
      </h5>
      <b-row>
        <b-col
          v-for="account in accounts"
          :key="account.id"
          cols="12"
          md="4"
          class="text-center"
        >
          <MyAccountCard :account="account" />
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row align-h="between">
        <b-col
          cols="auto"
          class="d-flex align-items-center"
        >
          {{ $t('create_pamm_here_title') }}
        </b-col>
        <b-col cols="auto">
          <b-button
            :to="{ name: 'pamm_management_create' }"
            variant="primary"
          >
            {{ $t('pamm_account_create') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>

  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import MyAccountCard from '../components/my-account-card.vue'

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BButton,
    MyAccountCard,
  },
  data() {
    return {
      show: false,
      accounts: [
        {
          id: 103,
          nick_name: 'hamid_wolf1366',
          balance: 1000,
          caneclation_period: 30,
          caneclation_fee: 10,
          fee_static: 10,
          fee_percent: 1.5,
          loss_share: 5,
          profit_share: 10,
          minimum_investment: 300,
          maximum_investment: 1000,
          rank: 1,
          created_at: new Date(),
          initial_capital: 100,
          activate: false,
        },
        {
          id: 104,
          nick_name: 'Jenifer1987',
          balance: 1000,
          caneclation_period: 30,
          caneclation_fee: 10,
          fee_static: 10,
          fee_percent: 1.5,
          loss_share: 5,
          profit_share: 10,
          minimum_investment: 300,
          maximum_investment: 1000,
          rank: 2,
          created_at: new Date(),
          initial_capital: 100,
          activate: true,
        },
        // {
        //   id: 1111,
        //   nick_name: 'SaraKhan111',
        //   balance: 1000,
        //   caneclation_period: 30,
        //   caneclation_fee: 10,
        //   fee_static: 10,
        //   fee_percent: 1.5,
        //   loss_share: 5,
        //   profit_share: 10,
        //   minimum_investment: 300,
        //   maximum_investment: 1000,
        //   rank: 3,
        //   created_at: new Date(),
        //   initial_capital: 100,
        //   activate: true,
        // },
      ],
    }
  },
  methods: {

  },
}
</script>

<style>
.pamm-button{
  background: linear-gradient(180deg, #068787 0%, #0A9292 48.96%, #035C68 100%) !important ;
  border:none !important ;
}
</style>
